import React from 'react'
import * as stylesBanner from '@styles/home/banner.module.css'

const Banner = () => (
  <div className={stylesBanner.container}>
    <div>
      <img
        src="/media/images/general/pc_sovos.svg"
        alt="Ilustracion"
        className={stylesBanner.img}
      />
    </div>
    <div className={stylesBanner.espace}></div>
    <p className={stylesBanner.p}>Bienvenidos a nuestro Development Center</p>
    <div className={stylesBanner.espace}></div>
  </div>
)

export default Banner
