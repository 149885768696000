import React from 'react'
import Header from '@components/layout/header'
import Banner from '@components/home/banner'
import Products from '@components/home/products'
import Footer from '@components/layout/footer'
import * as stylesIndex from '@styles/index.module.css'
import Seo from '@components/setting-site/seo'

const Home = () => (
  <div className={stylesIndex.container}>
    <Seo title="Developers Stupendo | Home" />
    <Header className={stylesIndex.footer} />
    <div className={stylesIndex.body}>
      <Banner className={stylesIndex.banner} />
      <Products />
    </div>
    <Footer className={stylesIndex.footer} />
  </div>
)

export default Home
