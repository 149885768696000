import React from 'react'
import * as stylesProducts from '@styles/home/products.module.css'
import { Link } from 'gatsby'

const Main = () => (
  <div className={stylesProducts.container}>
    <p className={stylesProducts.p}>Selecciona el producto para ver su documentación:</p>

    <Link to="/dec/introduction">
      <div className={stylesProducts.div}>
        <img
          src="/media/images/dec/logo_dec.svg"
          alt="Dec"
          className={stylesProducts.dec}
        />
      </div>
    </Link>
  </div>
)

export default Main
